import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/web/web/src/templates/default-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "800px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABnLkEs0TL/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREkP/2gAIAQEAAQUCVFqqDOh//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABkQAAIDAQAAAAAAAAAAAAAAAAAQAQIxIf/aAAgBAQAGPwLJOmqq/8QAGxABAAICAwAAAAAAAAAAAAAAAQBBECERMdH/2gAIAQEAAT8h4PKK1ug6jZtEtL7TH//aAAwDAQACAAMAAAAQp+//xAAVEQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAwEBPxBJ/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxDSpT//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUEQUeH/2gAIAQEAAT8Qh4JpmBArF0I66xQuXhfmaMdDMqGURX3s8f/Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "about-header",
        "title": "about-header",
        "src": "/static/d6b3a2ad53724142b1f7eb4c8ee6265a/4b190/alex.jpg",
        "srcSet": ["/static/d6b3a2ad53724142b1f7eb4c8ee6265a/e07e9/alex.jpg 200w", "/static/d6b3a2ad53724142b1f7eb4c8ee6265a/066f9/alex.jpg 400w", "/static/d6b3a2ad53724142b1f7eb4c8ee6265a/4b190/alex.jpg 800w", "/static/d6b3a2ad53724142b1f7eb4c8ee6265a/e5166/alex.jpg 1200w", "/static/d6b3a2ad53724142b1f7eb4c8ee6265a/644c5/alex.jpg 1440w"],
        "sizes": "(max-width: 800px) 100vw, 800px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <div className="z-10 leading-loose">
      <p>{`Introducing Alexandre Reis, a Software Engineer hailing from the vibrant city of Ovar, Portugal. 🇵🇹 `}</p>
      <p>{`With an unwavering passion for technology, he embarked on a journey that would shape his destiny and ignite his insatiable thirst for knowledge.`}</p>
      <p>{`From the tender age when his parents gifted him his very first desktop, Alexandre's world revolved around unraveling the mysteries of technology. In his youth, he would immerse himself in thrilling gaming adventures, captivated by the virtual realms that unfolded before him. As the years passed, his interests expanded beyond gaming, leading him to explore other realms like Photoshop, Blender, and Coding. But the thrill of gaming remained a cherished memory that fueled his passion for technology.`}</p>
      <p>{`Even during his high school years in the Economic field, Alexandre's mind would often wander to the exhilarating battles fought within the digital landscapes of his favorite games. It was clear that his path lay beyond the traditional confines of his studies.`}</p>
      <p>{`With a fire burning inside him, Alexandre took a bold step and applied for a High Technical Degree in Software Development. The moment he signed his first contract in July 2018, a wave of exhilaration swept over him. But he wasn't content to rest on his laurels. In September of the same year, he made the life-altering decision to pursue a Bachelor's Degree in Computer Engineering, driven by an unwavering determination to expand his horizons and elevate his skills to new heights.`}</p>
      <p>{`Thus began Alexandre's daily odyssey—a grueling 90-kilometer commute, twice a day, to attend classes and immerse himself in the intricacies of his degree. The sacrifices he made were colossal, but his unwavering dedication and hunger for knowledge propelled him forward, undeterred by the physical toll it took.`}</p>
      <p>{`With each passing day, Alexandre's fascination with the world of technology grew. Eager to stay ahead of the curve, he sought to augment his formal education with online courses, constantly pushing himself to explore new frontiers. And in 2023, he emerged triumphant, having completed his Bachelor's Degree in Computer Engineering. The vast realm of knowledge he acquired throughout his journey left him in awe.`}</p>
      <p>{`Beneath his tenacious work ethic and thirst for innovation, Alexandre possesses a spirited and light-hearted nature. He fondly recalls the joy of gaming in his youth, and it serves as a reminder of the excitement and wonder that technology can bring. Ever willing to lend a helping hand, he revels in the joy of assisting others in achieving their own aspirations. Pleasure and fulfillment are etched into the very fabric of his being, making him a truly remarkable individual.`}</p>
      <p>{`Such is the extraordinary tale of Alexandre Reis—a visionary, a dreamer, and a force to be reckoned with. With his goals set high and his heart ablaze, there is no doubt that he will continue to make indelible contributions to the ever-evolving world of technology. `}</p>
      <p>{`A pleasure to meet you, indeed! 👋`}</p>
    </div>
    <blockquote>
      <p parentName="blockquote">{`Through the words of ChatGPT, the extraordinary tale emerged with ❤️`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      